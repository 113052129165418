<template>
  <div class="category-set-tree">
    <div class="button-and-title">
      <div class="title-span">
        {{ source === "categoryConfig" ? "菜单列表" : "固定资产品类" }}
      </div>
      <div v-if="source === 'categoryConfig'">
        <r-button plain @click="$emit('updateTreeData', { parentId: 'ROOT' })"
          >添加根类型</r-button
        >
      </div>
    </div>
    <div class="filter-line">
      <el-input
        suffix-icon="el-icon-search"
        placeholder="请输入"
        v-model="searchKey"
        @input="treeFilterHandler"
      ></el-input>
    </div>
    <div class="tree-container" v-loading="loading">
      <el-tree
        v-bind="$attrs"
        :node-key="currentId"
        ref="treeIns"
        :default-checked-keys="checkedKeys"
        :default-expanded-keys="expandedKeys"
        icon-class="icon-class"
        @node-click="handleNodeClick"
        :data="treeData"
        :props="{
          id: this.currentId,
          label: this.currentLabel,
          children: 'children',
        }"
        :filter-node-method="filterNode"
      >
        <div
          slot-scope="{ node, data }"
          :data-level="data.level"
          class="custom-tree-node-wrapper"
          :class="{ 'node-selected': getSelected(data) }"
          :style="{ paddingLeft: getPaddingLeft(data.level, node.isLeaf) }"
          @mouseenter="mouseenterHandler(data)"
          @mouseleave="mouseenterHandler(null)"
        >
          <div class="custom-tree-node">
            <div class="node-wrapper">
              <div class="icon-expanded" v-if="!node.isLeaf">
                <img :src="node.expanded ? foldUrl : expandedUrl" />
              </div>
              <!-- <template>
                <img
                  v-if="!data.status"
                  :src="
                    data.type === FolderType || data.id === 'ROOT'
                      ? folderUrl
                      : fileUrl
                  "
                />
                <img v-else :src="fileDisabledUrl" />
              </template> -->

              <span
                class="tree-label"
                :class="{ 'tree-label-disabled': data.disabled }"
              >
                <el-tooltip
                  class="label-text"
                  :content="node.label"
                  placement="top"
                  :open-delay="500"
                  v-tooltip-auto-show
                >
                  <div class="label-text label-text-custom">
                    {{ node.label }}
                  </div>
                </el-tooltip>
              </span>
              <span
                class="line-operation"
                v-if="
                  hoverNodeId === data[currentId] && source === 'categoryConfig'
                "
              >
                <span class="add-button" @click.stop="setParentDatas(data)">
                  <i class="el-icon-circle-plus"></i>
                  添加子菜单
                </span>
                <span class="delete-button" @click="deleteHandler(data)">
                  <i class="el-icon-error"></i>
                  删除
                </span>
              </span>
            </div>
          </div>
        </div>
      </el-tree>
    </div>
  </div>
</template>

<script>
import foldUrl from "@/assets/images/workflow/fold.svg";
import expandedUrl from "@/assets/images/workflow/expanded.svg";
import {
  getAssetTree,
  getConsumableTree,
  getMaterielTree,
  assetDelete,
  consumableDelete,
  materielDelete,
} from "@/api/ruge/ams/basicConfig/categoryConfig";
// import { listToTree } from "@/utils/tree";
export default {
  name: "category-set-tree",
  props: {
    /**
     * 固定资产品类设置: asset
     * 易耗品品类设置: consumables
     * 物料品品类设置: material
     */
    type: {
      type: String,
      default() {
        return "asset";
      },
    },
    /**
     * 固定资产品类设置: categoryConfig
     * 设备参数模板: paramsTemplate
     */
    source: {
      type: String,
      default() {
        return "categoryConfig";
      },
    },
  },
  data() {
    return {
      foldUrl,
      expandedUrl,
      loading: false,
      searchKey: null,
      treeData: [],
      treeDataResource: [],
      checkedKeys: [],
      expandedKeys: [],
      selectedNode: {},
      hoverNodeId: null,
      deleteRequestMap: {
        asset: assetDelete,
        consumables: consumableDelete,
        material: materielDelete,
      },
      listRequestMap: {
        asset: getAssetTree,
        consumables: getConsumableTree,
        material: getMaterielTree,
      },
      idMap: {
        asset: "assetCategoryId",
        consumables: "consumableCategoryId",
        material: "materielCategoryId",
      },
      labelMap: {
        asset: "assetCategoryName",
        consumables: "consumableCategoryName",
        material: "materielCategoryName",
      },
    };
  },
  computed: {
    currentId() {
      return this.idMap[this.type];
    },
    currentLabel() {
      return this.labelMap[this.type];
    },
  },
  created() {
    this.initTreeDatas();
  },
  methods: {
    async deleteHandler(datas) {
      await this.$confirm(`是否确认删除?`, this.$t("commons.warning"), {
        confirmButtonText: this.$t("commons.confirm"),
        cancelButtonText: this.$t("commons.cancel"),
        type: "warning",
      });
      this.deleteRequestMap[this.type](datas[this.currentId]).then(() => {
        this.$message.success("删除成功！");
        // this.$emit("updateTreeData", { parentId: "ROOT" });
        this.initTreeDatas(this.findParentItemById(datas.parentId));
      });
    },
    mouseenterHandler(data) {
      this.hoverNodeId = data ? data[this.currentId] : null;
    },
    findParentItemById(parentId) {
      return this.treeDataResource.filter(
        (item) => item[this.currentId] === parentId
      )[0];
    },
    treeFilterHandler() {
      this.$refs.treeIns.filter(this.searchKey);
    },
    filterNode(value, data) {
      if (!value) return true;
      return data[this.currentLabel].includes(value);
    },
    initTreeDatas(expandNodeItem) {
      this.loading = true;
      this.listRequestMap[this.type]()
        .then((res) => {
          this.treeDataResource = res;
          this.treeData = this.listToTree(res, this.currentId, "parentId");
          console.log("this.treeData", this.treeData);
          this.setExpenedNodes(expandNodeItem);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    setExpenedNodes(expandNodeItem) {
      if (expandNodeItem) {
        this.expandedKeys = [expandNodeItem[this.currentId]];
        this.selectedNode = expandNodeItem;
        this.$emit("updateTreeData", expandNodeItem);
      } else {
        this.expandedKeys = [];
        this.$emit("updateTreeData", { parentId: "ROOT" });
      }
    },
    listToTree(list, node, parentNode) {
      // 检查输入参数是否合法
      if (
        !Array.isArray(list) ||
        typeof node !== "string" ||
        typeof parentNode !== "string"
      ) {
        return [];
      }
      const tree = [];
      const map = {};
      // 将每个节点以其 node 字段的值为键，节点对象本身为值，存储到 map 中
      list.forEach((item) => {
        map[item[node]] = item;
      });
      // 辅助函数：将子节点添加到父节点的 children 数组中，并设置子节点的层级
      const addToParent = (parent, child, level) => {
        if (!parent.children) {
          parent.children = [];
        }
        child.level = level;
        parent.children.push(child);
      };
      // 递归函数：处理节点及其子节点，设置层级
      const processNode = (currentNode, level) => {
        currentNode.level = level;
        const children = list.filter(
          (item) => item[parentNode] === currentNode[node]
        );
        if (children.length > 0) {
          currentNode.children = [];
          children.forEach((child) => {
            processNode(child, level + 1);
            addToParent(currentNode, child, level + 1);
          });
        }
      };
      // 找出根节点并处理
      list.forEach((item) => {
        if (!map[item[parentNode]]) {
          processNode(item, 0);
          tree.push(item);
        }
      });
      return tree;
    },
    toggleExpanded() {},
    setParentDatas(datas) {
      this.$emit("updateTreeData", {
        parentId: datas[this.currentId],
        parentName: datas[this.currentLabel],
      });
    },
    handleNodeClick(node) {
      this.selectedNode = node;
      this.$emit("updateTreeData", node);
    },
    getSelected(data) {
      const selectedNode = this.selectedNode;
      return data[this.currentId] === selectedNode[this.currentId];
    },
    getPaddingLeft(level, isLeaf) {
      if (!level) return "8px";
      let pl = 20 * level;
      if (isLeaf) {
        pl = pl + 10;
      }
      pl = pl + 8;
      return pl + "px";
    },
  },
};
</script>

<style lang="less" scoped>
.category-set-tree {
  padding: 0 20px;
  height: 100%;
  .button-and-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #e4e7eb;
    height: 58px;
    .title-span {
      font-weight: 600;
      font-size: 16px;
      color: #252d3d;
    }
  }
  .filter-line {
    height: 32px;
    margin: 20px 0;
  }
  ::v-deep {
    .icon-class {
      display: none;
    }
    .is-current {
      // background: #2a66ff15;
    }
    .el-tree-node__content > .el-tree-node__expand-icon {
      padding: 0 !important;
      border-radius: 6px;
    }

    .el-tree-node {
      margin-bottom: 0px !important;
      border-radius: 6px;
    }

    .el-tree-node__content:hover {
      background-color: initial !important;
    }

    .el-tree-node:focus > .el-tree-node__content {
      background-color: initial !important;
    }

    .el-tree-node__content {
      height: 100%;
      padding-left: 0 !important;
    }
    .el-tree-node > .el-tree-node__children {
      overflow: inherit;
    }
  }
  .tree-container {
    height: calc(100% - 145px);
    overflow: auto;
    .custom-tree-node-wrapper {
      position: relative;
      width: 100%;
      height: 34px;
      border-radius: 6px;
      display: flex;
      align-items: center;
      box-sizing: content-box;
      padding: 0 8px;
      &:hover {
        background-color: #f4f4f5;
      }
      &.node-selected {
        background: #eaf0ff;
      }
    }
    .custom-tree-node {
      width: 100%;
      display: flex;
      border-radius: 6px;
      align-items: center;

      // padding-left: 8px;

      // &.node-selected {
      //   background: #eaf0ff;
      // }

      // &:hover {
      //   background-color: #f4f4f5;
      // }
      .icon-expanded {
        width: 6px;
        cursor: pointer;
        padding: 8px 0;
        display: flex;
        justify-items: center;
        align-items: center;
      }

      .node-wrapper {
        display: flex;
        align-items: center;
        width: 100%;
        column-gap: 4px;
      }

      .tree-label {
        display: flex;
        justify-content: space-between;
        flex: auto;
        font-weight: 400;
        font-size: 14px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        .label-text {
          width: 145px;
          font-size: 14px;
          color: #252d3d;
          line-height: 16px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }

      .line-operation {
        font-size: 12px;
        .add-button {
          color: #2a61ff;
          margin-right: 8px;
        }
        .delete-button {
          color: #f13636;
        }
      }

      .tree-label-disabled {
        color: #5d687c;
      }

      .tree-tag-disabled {
        background: #ffefef;
        color: #f35555;
        border-radius: 2px 4px 2px 4px;
        padding: 2px 4px;
        font-size: 10px;
        margin-left: 14px;
      }
      .checkbox {
        position: absolute;
        right: 20px;

        .el-checkbox__input {
          line-height: 0;
        }
      }
    }
  }
}
</style>
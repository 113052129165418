var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "category-set-tree" }, [
    _c("div", { staticClass: "button-and-title" }, [
      _c("div", { staticClass: "title-span" }, [
        _vm._v(
          "\n      " +
            _vm._s(
              _vm.source === "categoryConfig" ? "菜单列表" : "固定资产品类"
            ) +
            "\n    "
        ),
      ]),
      _vm.source === "categoryConfig"
        ? _c(
            "div",
            [
              _c(
                "r-button",
                {
                  attrs: { plain: "" },
                  on: {
                    click: function ($event) {
                      return _vm.$emit("updateTreeData", { parentId: "ROOT" })
                    },
                  },
                },
                [_vm._v("添加根类型")]
              ),
            ],
            1
          )
        : _vm._e(),
    ]),
    _c(
      "div",
      { staticClass: "filter-line" },
      [
        _c("el-input", {
          attrs: { "suffix-icon": "el-icon-search", placeholder: "请输入" },
          on: { input: _vm.treeFilterHandler },
          model: {
            value: _vm.searchKey,
            callback: function ($$v) {
              _vm.searchKey = $$v
            },
            expression: "searchKey",
          },
        }),
      ],
      1
    ),
    _c(
      "div",
      {
        directives: [
          {
            name: "loading",
            rawName: "v-loading",
            value: _vm.loading,
            expression: "loading",
          },
        ],
        staticClass: "tree-container",
      },
      [
        _c(
          "el-tree",
          _vm._b(
            {
              ref: "treeIns",
              attrs: {
                "node-key": _vm.currentId,
                "default-checked-keys": _vm.checkedKeys,
                "default-expanded-keys": _vm.expandedKeys,
                "icon-class": "icon-class",
                data: _vm.treeData,
                props: {
                  id: this.currentId,
                  label: this.currentLabel,
                  children: "children",
                },
                "filter-node-method": _vm.filterNode,
              },
              on: { "node-click": _vm.handleNodeClick },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function ({ node, data }) {
                    return _c(
                      "div",
                      {
                        staticClass: "custom-tree-node-wrapper",
                        class: { "node-selected": _vm.getSelected(data) },
                        style: {
                          paddingLeft: _vm.getPaddingLeft(
                            data.level,
                            node.isLeaf
                          ),
                        },
                        attrs: { "data-level": data.level },
                        on: {
                          mouseenter: function ($event) {
                            return _vm.mouseenterHandler(data)
                          },
                          mouseleave: function ($event) {
                            return _vm.mouseenterHandler(null)
                          },
                        },
                      },
                      [
                        _c("div", { staticClass: "custom-tree-node" }, [
                          _c("div", { staticClass: "node-wrapper" }, [
                            !node.isLeaf
                              ? _c("div", { staticClass: "icon-expanded" }, [
                                  _c("img", {
                                    attrs: {
                                      src: node.expanded
                                        ? _vm.foldUrl
                                        : _vm.expandedUrl,
                                    },
                                  }),
                                ])
                              : _vm._e(),
                            _c(
                              "span",
                              {
                                staticClass: "tree-label",
                                class: { "tree-label-disabled": data.disabled },
                              },
                              [
                                _c(
                                  "el-tooltip",
                                  {
                                    directives: [
                                      {
                                        name: "tooltip-auto-show",
                                        rawName: "v-tooltip-auto-show",
                                      },
                                    ],
                                    staticClass: "label-text",
                                    attrs: {
                                      content: node.label,
                                      placement: "top",
                                      "open-delay": 500,
                                    },
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "label-text label-text-custom",
                                      },
                                      [
                                        _vm._v(
                                          "\n                  " +
                                            _vm._s(node.label) +
                                            "\n                "
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                            _vm.hoverNodeId === data[_vm.currentId] &&
                            _vm.source === "categoryConfig"
                              ? _c("span", { staticClass: "line-operation" }, [
                                  _c(
                                    "span",
                                    {
                                      staticClass: "add-button",
                                      on: {
                                        click: function ($event) {
                                          $event.stopPropagation()
                                          return _vm.setParentDatas(data)
                                        },
                                      },
                                    },
                                    [
                                      _c("i", {
                                        staticClass: "el-icon-circle-plus",
                                      }),
                                      _vm._v(
                                        "\n                添加子菜单\n              "
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "span",
                                    {
                                      staticClass: "delete-button",
                                      on: {
                                        click: function ($event) {
                                          return _vm.deleteHandler(data)
                                        },
                                      },
                                    },
                                    [
                                      _c("i", { staticClass: "el-icon-error" }),
                                      _vm._v(
                                        "\n                删除\n              "
                                      ),
                                    ]
                                  ),
                                ])
                              : _vm._e(),
                          ]),
                        ]),
                      ]
                    )
                  },
                },
              ]),
            },
            "el-tree",
            _vm.$attrs,
            false
          )
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
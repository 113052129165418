import request from '@/utils/request';
import { envInfo } from '@/constants/envInfo';

/**
 *  固定资产品类服务 - 树形数据列表
 * @param params
 */
export function getAssetTree(params) {
  return request({
    url: envInfo.bgApp.amsPath + '/category/asset/list',
    method: 'get',
    params,
  });
}

/**
 *  固定资产品类服务 - 保存
 * @param params
 * assetCategoryId 固定资产品类ID
 * parentId 固定资产品类父ID,没有传ROOT
 * assetCategoryName 固定资产品类名称
 * assetCategoryCode 固定资产品类编码
 * deptTypeList 部门类型集合
 */
export function assetSave(params) {
  return request({
    url: envInfo.bgApp.amsPath + '/category/asset/save',
    method: 'post',
    data: params,
  });
}

/**
 *  固定资产品类服务 - 删除
 * @param params
 */
export function assetDelete(assetId) {
  return request({
    url: envInfo.bgApp.amsPath + `/category/asset/delete/${assetId}`,
    method: 'delete',
  });
}

/**
 *  易耗品品类服务 - 树形数据列表
 * @param params
 */
export function getConsumableTree(params) {
  return request({
    url: envInfo.bgApp.amsPath + '/category/consumable/list',
    method: 'get',
    params,
  });
}

/**
 *  易耗品品类服务 - 保存
 * @param params
 * consumableCategoryId 易耗品品类ID
 * parentId 易耗品品类父ID,没有传ROOT
 * consumableCategoryName 易耗品品类名称
 * consumableCategoryCode consumableCode
 */
export function consumableSave(params) {
  return request({
    url: envInfo.bgApp.amsPath + '/category/consumable/save',
    method: 'post',
    data: params,
  });
}

/**
 *  易耗品品类服务 - 删除
 * @param params
 */
export function consumableDelete(consumableCategoryId) {
  return request({
    url:
      envInfo.bgApp.amsPath +
      `/category/consumable/delete/${consumableCategoryId}`,
    method: 'delete',
  });
}

/**
 *  物料品类服务 - 树形数据列表
 * @param params
 */
export function getMaterielTree(params) {
  return request({
    url: envInfo.bgApp.amsPath + '/category/materiel/list',
    method: 'get',
    params,
  });
}

/**
 *  物料品类服务 - 保存
 * @param params
 * materielCategoryId 物料品类ID
 * parentId 物料品类父ID,没有传ROOT
 * materielCategoryName 物料品类名称
 * materielCategoryCode 物料品类编码
 */
export function materielSave(params) {
  return request({
    url: envInfo.bgApp.amsPath + '/category/materiel/save',
    method: 'post',
    data: params,
  });
}

/**
 *  物料品类服务 - 删除
 * @param params
 */
export function materielDelete(materielId) {
  return request({
    url: envInfo.bgApp.amsPath + `/category/materiel/delete/${materielId}`,
    method: 'delete',
  });
}
